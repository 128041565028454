<script>
import { layoutComputed } from "@/state/helpers";
import Vertical from "./vertical";
import Horizontal from "./horizontal";
import ambienteConfig from "@/ambiente.config";
export default {
  components: { Vertical, Horizontal },
  data() {
    return {
      is_sandbox: false,
    };
  },
  created() {
    if (ambienteConfig.APP_ENV == 'sandbox') {
      this.is_sandbox = true
    } else {
      this.is_sandbox = false
    }
  },
  computed: {
    ...layoutComputed
  },
  methods: {}
};
</script>

<style>
.is_sandbox {
  position: fixed;
  right: 0;
  bottom: 0px;
  background: red;
  color: #fff;
  z-index: 99999;
  display: block;
  width: 172px;
  height: 150px;
  border-radius: 200px 0 0 0 ;
  font-size: 27px;
  line-height: 100%;
  padding-top: 25px;
  padding-right: 10px;
  text-align: right;
}
</style>
<template>
  <div>
    <div v-if="is_sandbox" class="is_sandbox">Isso é sandbox</div>
    <vertical v-if="layoutType === 'vertical'" :layout="layoutType">
      <slot />
    </vertical>

    <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
      <slot />
    </Horizontal>
  </div>
</template>
